<template>
  <div class="content">
    <table class="table">
      <thead class="table__header">
        <tr class="table__row">
          <th class="table__header-cell -first">
            <span class="table__header-cell-content">Wort</span>
          </th>
          <th class="table__header-cell -last">
            <span class="table__header-cell-content">Synonym(e)</span>
          </th>
        </tr>
      </thead>
      <tbody class="table__body">
      <tr class="table__row" v-for="(synonym, index) in sortedArray(routerData)" v-bind:key="index">
        <td class="table__cell -first">
          <div class="table__cell-content -first">
            {{ synonym.key }} <div class="table__word-count" v-if="synonym.replacements.length > 1">({{synonym.replacements.length}})</div>
          </div>
        </td>
        <td class="table__cell -last">
          <div class="table__cell-content -last">
            <div class="table__replaces" v-html="replacements(synonym)">
            </div>
            <button class="button edit-button" @click="deleteSynonym(synonym.id)">
              <Icon name="delete-button" css-class="-edit" />
            </button>
            <router-link class="button edit-button" v-bind:to="`/${customerId}/synonym/${synonym?.id}`">
              <Icon name="edit-button" css-class="-edit" />
            </router-link>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="content__button">
    <button class="button" @click="openModal">
      <Icon name="add-button" css-class="-add" />
    </button>
  </div>
  <Modal
      v-bind:show-modal="modalOpen"
      header-text="Synonym hinzufügen"
      button-text="Hinzufügen"
      icon-id="checkmark"
      @toggle-modal="modalOpen = false"
      @submit-click="appendSynonym"
      :centered="true"
  >
    <template v-slot:body>
      <div class="form">
        <label class="label">Wort</label>
        <input type="text" v-model="synonymInput" ref="synonymInput" v-on:keydown.enter.prevent.stop="appendSynonym" />
      </div>
    </template>
  </Modal>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";

export default {
  name: 'SynonymListWidget',
  components: {
    Icon,
    Modal,
  },
  props: {
    customerId: {
      type: String,
    },
    routerData: {
      type: Object,
    },
    dataHandler: {
      type: Object,
    },
    layoutIndex: {
      type: Number,
    },
    draggingSortItem: {
      type: Number,
    },
    activeIds: {
      type: Array,
    },
    activeFilterToggled : {
      type: Boolean,
    }
  },
  data() {
    return {
      modalOpen: false,
      deletionModalOpen: false,
      synonymInput: '',
      deletionId: null,
      data: this.routerData,
    };
  },
  computed: {
  },
  methods: {
    replacements(synonym) {
      return synonym?.replacements.map((replacement) => replacement.value).join(' ')
    },
    deleteSynonym(id) {
      this.deletionModalOpen = true;
      this.deletionId = id
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteSynonym(this.deletionId);
      }
      this.deletionModalOpen = false;
    },
    appendSynonym() {
      if (this.synonymInput.length > 0) {
        const nextSynonymId = this.dataHandler?.getNextSynonymId();
        this.dataHandler?.appendSynonym(this.synonymInput);
        this.modalOpen = false;
        this.synonymInput = '';
        this.$router.push({ name: 'Synonym', params: { synonymId: nextSynonymId } });
      }
    },
    openModal() {
      this.modalOpen = true;
      this.$nextTick(() => {
        this.$refs.synonymInput.focus();
      });
    },
    sortedArray(array) {
      function SortArray(x, y){
        return x.key.localeCompare(y.key);
      }
      return array.sort(SortArray);
    }
  },
}
</script>

<style lang="scss" scoped>

.table {
  &__header-cell {
    &.-first {
      width: rem(200px);
    }

    &.-last {
      padding-right: 10px;
      flex-grow: 1;
      overflow-x: hidden;
      max-width: none;
      width: auto;
    }
  }

  &__cell {
    &.-first {
      font-weight: 500;
    }
  }

  &__cell-content {
    &.-last {
      display: flex;
      justify-content: space-between;
    }
  }

  &__word-count {
    margin-left: rem(5px);
  }

  &__replaces {
    padding-right: rem(10px);
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.form {

  input {
    padding: rem(10px) rem(15px);
    border: 2px solid var(--border-light-2);
    border-radius: 5px;
  }
}

</style>